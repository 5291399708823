var originalJQueryReady = jQuery.fn.ready;
var wrapHandler = function(fnOriginHandler) {
	return function() {
		try {
			return fnOriginHandler.apply(this, arguments);
		} catch (e) {
			// Do nothing.
		}
	};
};
jQuery.fn.ready = function(handler) {
	// Call original jQuery.fn.ready, with the proxied handler.
	return originalJQueryReady.call(this, wrapHandler(handler));
};
//  Fix the jquery.browser fall back issue in 1.9.0 - needed for qtip plugin
//  http://stackoverflow.com/questions/14365725/easiest-lightest-replacement-for-browser-detection-jquery-1-9
var matched, browser;

// Use of jQuery.browser is frowned upon.
// More details: http://api.jquery.com/jQuery.browser
// jQuery.uaMatch maintained for back-compat
jQuery.uaMatch = function(ua) {
    ua = ua.toLowerCase();

    var match = /(chrome)[ \/]([\w.]+)/.exec(ua) ||
            /(webkit)[ \/]([\w.]+)/.exec(ua) ||
            /(opera)(?:.*version|)[ \/]([\w.]+)/.exec(ua) ||
            /(msie) ([\w.]+)/.exec(ua) ||
            ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua) ||
            [];

    return {
        browser: match[ 1 ] || "",
        version: match[ 2 ] || "0"
    };
};

matched = jQuery.uaMatch(navigator.userAgent);
browser = {};

if (matched.browser) {
    browser[ matched.browser ] = true;
    browser.version = matched.version;
}

// Chrome is Webkit, but Webkit is also Safari.
if (browser.chrome) {
    browser.webkit = true;
} else if (browser.webkit) {
    browser.safari = true;
}

jQuery.browser = browser;



var menuTimeoutId;
$(function() {
    // Fallback for HTML5 placeholder
    // http://www.scriptiny.com/2012/08/html5-placeholder-fallback-using-jquery/
    if (!("placeholder" in document.createElement("input"))) {
        $("input[placeholder], textarea[placeholder]").each(function() {
            var val = $(this).attr("placeholder");
            if (this.value == "") {
                this.value = val;
            }
            $(this).focus(function() {
                if (this.value == val) {
                    this.value = "";
                }
            }).blur(function() {
                if ($.trim(this.value) == "") {
                    this.value = val;
                }
            })
        });

        // Clear default placeholder values on form submit
        $('form').submit(function() {
            $(this).find("input[placeholder], textarea[placeholder]").each(function() {
                if (this.value == $(this).attr("placeholder")) {
                    this.value = "";
                }
            });
        });
    }



    //Trigger Back to Top function
    $.fn.scrollToTop = function() {
        $(this).hide().removeAttr("href");
        if ($(window).scrollTop() != "0") {
            $(this).fadeIn("slow")
        }
        var scrollDiv = $(this);
        $(window).scroll(function() {
            if ($(window).scrollTop() == "0") {
                $(scrollDiv).fadeOut("slow")
            } else {
                $(scrollDiv).fadeIn("slow")
            }
        });
        $(this).click(function() {
            $("html, body").animate({
                scrollTop: 0
            }, 300);
        });
    }

    //initialize the toTop item
    $("#toTop").scrollToTop();


    //Trigger the Home Page Carousel & add options
    $('#mainCarousel, #partnersCarousel').carousel({
        interval: 4000,
        pause: "hover"
    })

    $('#testimonialsCarousel, #packageCarousel').carousel({
        interval: 8000,
        pause: "hover"
    })

    //Set Up pop overs ***Is this referenced by code in the DB??? No other references found in this project***
    $('.imgGrid-popover').popover('hide');

        //only activate selectpicker on desktop.
    if(!Modernizr.touch) {
        $('.selectpicker').selectpicker();
    }
    else {
        //we have some styling setting the visibility to hidden by default in some areas.
        $('.selectpicker').css('visibility','visible');
    }

    //always use select picker for the region select dropdown in the menubar
    $('.regionSelect').selectpicker();


    // My Account add/remove class for bg images on collapse/expand sections
    $('.event-body.in').closest("li").addClass("event-expanded");
    $(".expand-btn a").click(function() {
        $(this).closest('li').toggleClass('event-expanded');
    });

    //Make fist tab active on load
    $('#SocialTabs-RB a:first').tab('show');

    if (!isTouchDevice()) {
        $('.nav .dropdown .dropdown-toggle').click(function(e) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        });
        //apply dropdown hover
        jQuery('ul.nav li.dropdown').hover(function() {
            clearTimeout(menuTimeoutId)
            var thiss = $(this);
            menuTimeoutId = setTimeout(function() {
                thiss.closest('.dropdown-menu').stop(true, true).show();
                thiss.addClass('open');
            }, 500)

        }, function() {
            clearTimeout(menuTimeoutId)
            jQuery(this).closest('.dropdown-menu').stop(true, true).hide();
            jQuery(this).removeClass('open');
        });
    }

    //Adding zebra striping to Sell Page 
    //Step 2 Selected events
    $('.selected-events li:nth-child(2n+1)').addClass('odd');

});

// Livechat Popup Window
var livechatPopup = null;

function openLivechatPopup() {

    if(livechatPopup === null || livechatPopup.closed) {
		livechatPopup = window.open('/account/Chat.action','_blank','toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=700,top=0,left=0');
        return false;
    }
    else {
        livechatPopup.focus();
    }
	
}

